import React from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { Column } from '@ant-design/plots';
import Request from '../../utils/Request';
import { api } from '../../api/ApiConfig';
import "@ant-design/flowchart/dist/index.css";

type StockDayInfo = {
    date: string,
    count: number,
    stocks: number,
}

type StatisticInfo = {
    count: number,
    dataSize: number,
    detail: StockDayInfo[],
}

type Details = {
    type: string,
    count: number,
    date: string,
}

function Dashboard() {

    const [stockStatus, setStockStatus] = React.useState('Close');
    const [statistic, setStatistic] = React.useState<StatisticInfo | null>(null);
    const [details, setDetails] = React.useState<Details[]>(new Array<Details>(0));

    React.useEffect(() => {
        Request({
            url: api.getStockTradeState.path,
            method: 'get',
        }, (res) => {
            setStockStatus(res.data.data.status);
        });
        Request({
            url: api.getStockStatistic.path,
            method: 'get',
        }, (res) => {
            setStatistic(res.data.data);
            let array = new Array<Details>(0);
            res.data.data.detail.forEach((item: StockDayInfo, index: number) => {
                if(index < 10){
                    array.push({
                        type: '收集次数',
                        count: item.count,
                        date: item.date,
                    });
                    array.push({
                        type: '数据条目',
                        count: item.stocks,
                        date: item.date,
                    });
                }
                
            });
            setDetails(array);
        });
    }, []);
    return (
        <div>
            <Row gutter={24}>
                <Col span={8}>
                    <Card>
                        <Statistic
                            title='股市状态'
                            value={stockStatus}
                            valueStyle={stockStatus === 'Open' ? { color: '#3f8600', textAlign: 'center' } : { color: '#cf1322', textAlign: 'center' }}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic
                            title='收集数据条目'
                            value={statistic?.count}
                            valueStyle={{ color: '#4141EE', textAlign: 'center' }}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card>
                        <Statistic
                            title='数据所占空间'
                            value={statistic ? statistic.dataSize / (1024 * 1024) : 0}
                            precision={2}
                            valueStyle={{ color: '#D870F2', textAlign: 'center' }}
                            suffix="MB"
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: 20 }}>
                <Col span={24}>
                    <Card title='收集记录统计'>
                        <Column
                            data={details}
                            xField='date'
                            yField='count'
                            seriesField='type'
                            isGroup={true}
                            columnStyle={{ radius: [20, 20, 0, 0] }}
                        />
                    </Card>

                </Col>
            </Row>
        </div>
    );
}

export default Dashboard;
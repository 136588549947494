import Recaptcha from 'react-recaptcha';
import React from 'react';
import { Button, Form, FormInstance, Image, Input, Space } from 'antd';
import Request from '../../utils/Request';
import { api } from '../../api/ApiConfig';
import Logo from '../../poseidon_pure.jpg';
import './Login.css';

function Login() {

    const loginFormRef = React.createRef<FormInstance>();

    const [recaptchaResponse, setRecaptchaResponse] = React.useState('');

    // React.useEffect(() => {}, [recaptchaResponse]);

    const handleLogin = (values: any) => {
        if (recaptchaResponse !== undefined && recaptchaResponse !== '') {
            let payload = {
                g_recaptcha_response: recaptchaResponse,
                email: values.email,
                password: values.password,
            };
            Request({
                url: api.login.path,
                method: 'post',
                data: payload,
            }, (res) => {
                localStorage.setItem("id", res.data.data.id);
                localStorage.setItem("name", res.data.data.name);
                localStorage.setItem("token", res.data.data.token);
                window.location.href = "/";
            });
        }
    };

    const handleVerify = (response: any) => {
        console.log("response-->" + response);
        setRecaptchaResponse(response);
    };

    const handleExpired = () => {
        setRecaptchaResponse('');
    };

    const handleReset = () => {
        loginFormRef.current?.resetFields();
    };

    return (
        <div className='login-page'>
            <div className="login-logo">
                <Image src={Logo} preview={false} />
            </div>
            <div className='login-card-layout'>
                <div className='login-card'>
                    <Form
                        ref={loginFormRef}
                        style={{ marginTop: 40 }}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        onFinish={handleLogin}
                    >
                        <Form.Item
                            label="Email"
                            name='email'
                            rules={[{ required: true, message: 'Email不能为空' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="密码"
                            name='password'
                            rules={[{ required: true, message: '密码不能为空' }]}
                        >
                            <Input type='password' />
                        </Form.Item>
                        <div style={{ marginLeft: 115, marginBottom: 20, }}>
                            <Recaptcha
                                sitekey='6Lc-hN0aAAAAAOOK_AtI6mTf4lEuRNTcb9Iek0mF'
                                verifyCallback={handleVerify}
                                render="explicit"
                                expiredCallback={handleExpired}
                            />

                        </div>
                        <div>
                            <Space style={{ marginLeft: 170 }} size='large'>
                                {recaptchaResponse !== undefined && recaptchaResponse !== '' ?
                                    <Button type='primary' htmlType="submit">提交</Button> :
                                    <Button type='primary' htmlType="submit" disabled>提交</Button>
                                }
                                <Button type='default' onClick={handleReset}>重置</Button>
                            </Space>
                        </div>
                    </Form>
                </div>

            </div>
        </div>
    )
}
export default Login;
import Mock from 'mockjs';
import { api } from '../api/ApiConfig';

export const loginMock = Mock.mock(new RegExp(api.login.path), api.login.method, (option: any) => {
    return ({
        code: 1000,
        message: 'success',
        data: {
            id: '1',
            name: 'demo',
            token: 'demo-mock-token',
        }
    });
});

export const logoutMock = Mock.mock(new RegExp(api.logout.path), api.logout.method, (option: any) => {
    return ({
        code: 1000,
        message: 'success',
    });
});
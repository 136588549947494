import React from 'react';
import ProLayout, { PageContainer } from '@ant-design/pro-layout';
import { Avatar, Popover, Space } from 'antd';
import { Routes, Route, Navigate, Link} from 'react-router-dom';
import type { ProSettings } from '@ant-design/pro-layout';

import Logo from '../poseidon_pure.jpg';
import Request from '../utils/Request';
import { api } from '../api/ApiConfig';
import Dashboard from '../pages/dashboard/Dashboard';
import Job from '../pages/job/Job';
import menuProps from './MenuProps';
import { UserOutlined } from '@ant-design/icons';
import '@ant-design/pro-layout/dist/layout.css';

function Main(props: any) {
    // console.log("reload");
    // console.log(window.location.hash.substring(1));
    const [pathname, setPathname] = React.useState(window.location.hash.substring(1));
    const [settings, setSettings] = React.useState<Partial<ProSettings> | undefined>({ 
        title: 'Poseidon',
        navTheme: 'light',
    });

    // React.useEffect(() => {
    //     console.log(pathname);
    // }, [pathname]);

    const handleLogout = () => {
        Request({
            url: api.logout.path,
            method: 'get',
        }, (res) => {
            localStorage.removeItem("id");
            localStorage.removeItem("name");
            localStorage.removeItem("token");
            window.location.href="/";
        });
    }

    return (
        <ProLayout 
            {...menuProps}
            location={{
                pathname: pathname,
            }}
            logo={Logo}
            fixSiderbar
            menuItemRender={(item, dom) => (
                <Link to={item.path as string} onClick={() => {
                    setPathname(item.path as string);
                    console.log(item.path, pathname);
                }} >{dom}</Link>
            )}
            rightContentRender={() => (
                <Popover content={<a onClick={handleLogout} >退出登录</a>}>
                    <Space>
                        <Avatar shape='square' size='small' icon={<UserOutlined />}  />
                        {localStorage.getItem('name')}
                    </Space>
                </Popover>
            )}
            {...settings}
        >
            <PageContainer>
                <Routes>
                    <Route path="/" element={<Navigate replace to="/dashboard" />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/job" element={<Job />} />
                </Routes>
            </PageContainer>
        </ProLayout>
    );
}

export default Main;
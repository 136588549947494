import Mock from 'mockjs';
import { api } from '../api/ApiConfig';

export const getStockTradeStateMock = Mock.mock(new RegExp(api.getStockTradeState.path), api.getStockTradeState.method, (option: any) => {
    return ({
        code: 1000,
        message: 'success',
        data: {
            status: "Open",
        }
    });
});

export const getStockStatisticMock = Mock.mock(new RegExp(api.getStockStatistic.path), api.getStockStatistic.method, (option: any) => {
    return ({
        code: 1000,
        message: 'success',
        data: {
            count: 56,
            dataSize: 50000000,
            detail: [
                {
                    date: '2022-03-22',
                    count: 10,
                    stocks: 4299,
                },
                {
                    date: '2022-03-21',
                    count: 10,
                    stocks: 4299,
                },
                {
                    date: '2022-03-20',
                    count: 10,
                    stocks: 4199,
                },
                {
                    date: '2022-03-19',
                    count: 10,
                    stocks: 4099,
                },
                {
                    date: '2022-03-18',
                    count: 10,
                    stocks: 4089,
                },
            ],
        }
    });
});

export const updateStockTradeDateMock = Mock.mock(new RegExp(api.updateStockTradeDate.path), api.updateStockTradeDate.method, (option: any) => {
    return ({
        code: 1000,
        message: 'success',
    });
});

export const getJobStatusMock = Mock.mock(new RegExp(api.getJobStatus.path), api.getJobStatus.method, (option: any) => {
    return({
        code: 1000,
        message: 'success',
        data: {
            status: Mock.Random.pick(['Active', 'Inactive']),
        }
    });
});

export const startFetchAStockDataJobMock = Mock.mock(new RegExp(api.startFetchAStockDataJob.path), api.startFetchAStockDataJob.method, (option: any) => {
    return ({
        code: 1000,
        message: 'success',
    });
});

export const stopFetchAStockDataJobMock = Mock.mock(new RegExp(api.stopFetchAStockDataJob.path), api.stopFetchAStockDataJob.method, (option: any) => {
    return ({
        code: 1000,
        message: 'success',
    });
});

export const getAStockDataMock = Mock.mock(new RegExp(api.getAStockData.path), api.getAStockData.method, (option: any) => {
    return ({
        code: 1000,
        message: 'success',
    });
});
import { PieChartOutlined, ExperimentOutlined, SettingOutlined, ToolOutlined } from '@ant-design/icons';

export default {
    route: {
        path: '/',
        routes: [
            {
                key: '1',
                path: '/dashboard',
                name: 'Dashboard',
                icon: <PieChartOutlined />,
                component: './pages/dashboard/Dashboard',
            },
            {
                key: '2',
                path: '/job',
                name: '任务管理',
                icon: <ToolOutlined />,
                component: './pages/job/Job',
            },
                
        ],
    },

}
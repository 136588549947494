import { Result, Button } from 'antd';
function Success() {

    const handleGo = () => {
        window.location.href = "/";
    };

    setTimeout(handleGo, 5000);

    return (
        <Result
            style={{
                marginTop: 150,
            }}
            status='success'
            title='注册成功'
            subTitle="页面将在5s内自动跳转，也可以直接点击按钮跳转。"
            extra={[
                <Button type="primary" key="go" onClick={handleGo}>跳转</Button>,
            ]}
        />
    );
}

export default Success;
import React from 'react';
import { Card, Col, Row, Button, Space, Tag } from 'antd';
import ProList from '@ant-design/pro-list';
import { toast } from 'react-toastify';
import { AndroidFilled } from '@ant-design/icons';
import '@ant-design/pro-list/dist/list.css';
import Request from '../../utils/Request';
import { api } from '../../api/ApiConfig';

function Job() {

    const FETCH_A_STOCK_DATA_JOB = 'fetch_a_stock_data_job'
    const [stockAJobStatus, setStockAJobStatus] = React.useState<string>('');

    React.useEffect(() => {
        Request({
            url: api.getJobStatus.path,
            method: 'get',
            params: {
                id: FETCH_A_STOCK_DATA_JOB,
            }
        }, (res) => {
            setStockAJobStatus(res.data.data.status);
        });
    }, []);

    let columns = {
        title: {
            dataIndex: 'name',
        },
        avatar: {
            dataIndex: 'image',
        },
        description: {
            dataIndex: 'desc',
        },
        subTitle: {
            render: (text: any, record: any, index: any) => {
                return (
                    <Space size={0}>
                        {index === 0 ? <Tag color={stockAJobStatus === 'Active' ? "green" : "red"}>{stockAJobStatus}</Tag> : <></>}
                    </Space>
                );
            },
        },
        actions: {
            render: (text: any, row: any, index: any) => [
                <Space size='large' key={1}>
                    {stockAJobStatus === 'Active' ?
                        <Button type='primary' onClick={() => handleJobStop(index)}>停止</Button> :
                        <Button type='primary' onClick={() => handleJobStart(index)}>启动</Button>
                    }
                    <Button type='default' onClick={() => handleImmediatelyRun(index)}>立刻执行一次</Button>
                </Space>
            ],
        },
    };

    const handleImmediatelyRun = (index: number) => {
        Request({
            url: api.getAStockData.path,
            method: 'get',
        }, (res) => {
            console.log(res.data.message);
            toast("执行成功", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const handleUpdateStockTradeData = () => {
        Request({
            url: api.updateStockTradeDate.path,
            method: 'get',
        }, (res) => {
            console.log(res.data.message);
            toast("更新成功", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    };

    const handleJobStart = (index: any) => {
        if (index === 0) {
            Request({
                url: api.startFetchAStockDataJob.path,
                method: 'get',
            }, (res) => {
                toast("Job启动成功", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setStockAJobStatus('Active');
            });
        }
    };

    const handleJobStop = (index: any) => {
        if (index === 0) {
            Request({
                url: api.stopFetchAStockDataJob.path,
                method: 'get',
            }, (res) => {
                toast("Job停止成功", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setStockAJobStatus('Inactive');
            });
        }
    };

    const jobData = [
        {
            name: "A股实时数据收集Job",
            image: <AndroidFilled />,
            desc: "后台任务——A股实时数据收集",
        }
    ];

    return (
        <div>
            <Row gutter={24}>
                <Col span={8}>
                    <Space size='large'>
                        <Button type='primary' onClick={handleUpdateStockTradeData}>更新股市交易日</Button>
                    </Space>
                </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: 20 }}>
                <Col span={24}>
                    <ProList<any>
                        rowKey="name"
                        headerTitle="任务列表"
                        tooltip="后台任务列表"
                        dataSource={jobData}
                        showActions="always"
                        metas={columns}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default Job;
export const api = {
    // user
    login: {
        path: '/api/user/login',
        method: 'post',
    },

    logout: {
        path: '/api/user/logout',
        method: 'get',
    },

    // task
    getStockTradeState: {
        path: '/api/task/stock_trade_state',
        method: 'get',
    },

    getStockStatistic: {
        path: '/api/task/stock_statistic',
        method: 'get',
    },

    updateStockTradeDate: {
        path: '/api/task/update_stock_trade_date',
        method: 'get',
    },

    getJobStatus: {
        path: '/api/task/status',
        method: 'get'
    },

    startFetchAStockDataJob: {
        path: '/api/task/start_fetch_a_stock_data_job',
        method: 'get',
    },

    stopFetchAStockDataJob: {
        path: '/api/task/stop_fetch_a_stock_data_job',
        method: 'get',
    },

    getAStockData: {
        path: '/api.task/get_a_stock_data',
        method: 'get',
    },
}
import { Routes, Route, Navigate } from 'react-router-dom';
// import { api } from './api/ApiConfig';
import Login from './pages/login/Login';
import Success from './pages/login/Success';
import Main from './layout/Main';
import './App.css';

if(process.env.REACT_APP_MOCK === 'true'){
  require('./mock/UserApi');
  require('./mock/TaskApi');
}

function App() {
  const isLogin = localStorage.getItem("name") ? true : false;
  const DefaultPage = isLogin ? <Main /> : <Navigate replace to="/login" />;
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/success" element={<Success />} />
      <Route path="*" element={DefaultPage} />
    </Routes>
  );
}

export default App;
